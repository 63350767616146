import { projectsCollection } from '../../firebase';
import router from '../../router';
import * as bootstrap from 'bootstrap';

const state = {
    projects: [],
    current_sort_type: 'last_updated',
    current_sort_ascending: false,
};

const getters = {
    projects: ({ projects }) => projects,

    projectsTotal: state => {
        return state.projects.length
    },

    sortedProjects: (state) => {
        let sortedProjects = state.projects;

        if(state.current_sort_type == 'created_on' || state.current_sort_type == 'last_updated') { // Numerical
            sortedProjects.sort((a, b) => {
                return a[state.current_sort_type] - b[state.current_sort_type]
            })
        } else { // Alphabetical
            sortedProjects.sort((a,b) => {
                let fa = a[state.current_sort_type].toLowerCase(), fb = b[state.current_sort_type].toLowerCase();
                if (fa < fb) {
                    return -1
                }
                if (fa > fb) {
                    return 1
                }
                return 0
            })
        }

        if(!state.current_sort_ascending){
			sortedProjects.reverse()
		}
        
        return sortedProjects
    },

    getPassword: (state) => (projectName) => {
        state.projects.forEach(project => {
            if(project.name === projectName) {
                return project.password
            }
        })
    },
};

const mutations = {
    SET_PROJECTS(state, val) {
        state.projects = val;
    },
    PROJECT_DETAILS(state, project) {
        state.projects.push({
            id: project.id,
            name: project.name,
            created_by: project.created_by,
            created_on: project.created_on,
            last_updated: project.last_updated
        })
    },
    PROJECT_PASSWORD(state, data) {
        state.projects.forEach(project => {
            if(data.id == project.id) {
                project.password = data.password;
            }
        })
    },
    SORT_PROJECTS: (state, sortType) => {
        if(sortType == state.current_sort_type) {
            return state.current_sort_ascending = !state.current_sort_ascending;
        } else {
            return state.current_sort_type = sortType;
        }
    },
};

const actions = {
    fetchProjects({ commit }) {
        console.log('action: fetchProjects');
        projectsCollection.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('empty collection');
          } else {
            let projectsDB = [];
            snapshot.forEach(doc => {
                projectsDB.push({
                    id: doc.id,
                    name: doc.data().name,
                    created_by: doc.data().created_by,
                    created_on: doc.data().created_on,
                    last_updated: doc.data().last_updated,
                    password: doc.data().password,
                });
            });
  
            commit('SET_PROJECTS', projectsDB);
          }
        });
    },

    createProject({ commit, dispatch, rootState }) {
        console.log('action: createProject');
        // Add project to firestore DB
        const form = document.querySelector('#create-project-form');
            
        projectsCollection.add({
            name: form.name.value,
            created_by: rootState.user.userProfile.name,
            created_on: Date.now(),
            last_updated: Date.now()
        })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                // -> mutation -> state
                const project = { 
                    id: docRef.id,
                    name: form.name.value, 
                    created_by: rootState.user.userProfile.name, 
                    created_on: Date.now(),
                    last_updated: Date.now(),
                    password: null,
                };
                commit('PROJECT_DETAILS', project);

                router.push({ name: 'Project', params: { name: form.name.value } })

                // Close create project modal
                dispatch('closeModal');
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    },

    savePassword({ commit }, { projectId, newPassword, id }) {
        console.log('action: savePassword');
        // Update password field 
        projectsCollection.doc(projectId).update({
            password: newPassword,
        })
        .then(() => {
            const data = {
                id: projectId,
                password: newPassword,
            }
            commit('PROJECT_PASSWORD', data);

            // Success notification
            const savePasswordBtn = document.getElementById(id);
            savePasswordBtn.classList.add('disabled');
            savePasswordBtn.innerHTML = '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0 .3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-11.00000002 11.00000002c-.36048396.3604839-.92771502.3882135-1.32000622.0831886l-.09420734-.0831886-5-5c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136.36048396-.3604839.92771502-.3882135 1.32000622-.0831886l.09420734.0831886 4.29289322 4.2921068z" fill="#242831"/></svg>'
            setTimeout(function(){
                savePasswordBtn.classList.remove('disabled');
                savePasswordBtn.innerHTML = 'Save'
            }, 3000)
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
    },

    getPassword({ state }, { projectName } ) {
        state.projects.forEach(project => {
            if(project.name === projectName) {
                return project.password
            }
        })
    },

    closeModal() {
        const myModalEl = document.getElementById('createProjectModal');
        const modal = bootstrap.Modal.getInstance(myModalEl);
        modal.toggle();
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}