<template>
    <!-- Normal btn -->
    <router-link v-if="type !== 'submit'" :type="type" :to="to" :id="id" :class="`btn btn-${size} ${[outline ? `btn-outline-${variant}` : `btn-${variant}`]} ${[disabled ? 'disabled' : '']}`" @click="modalId ? openModal(modalId) : ''">
        <span v-if="iconPosition === 'left'" class="btn__icon btn__icon--left">
            <inline-svg :src="require(`../assets/svg/${icon}.svg`)" />
        </span>
        <span>{{ text }}</span>
        <span v-if="iconPosition === 'right'" class="btn__icon btn__icon--right">
            <inline-svg :src="require(`../assets/svg/${icon}.svg`)" />
        </span>
    </router-link>
    <!-- Submit btn -->
    <button v-else :id="id" :class="`btn btn-${size} ${[outline ? `btn-outline-${variant}` : `btn-${variant}`]} ${[disabled ? 'disabled' : '']}`">
        <span v-if="iconPosition === 'left'" class="btn__icon btn__icon--left">
            <inline-svg :src="require(`../assets/svg/${icon}.svg`)" />
        </span>
        <span>{{ text }}</span>
        <span v-if="iconPosition === 'right'" class="btn__icon btn__icon--right">
            <inline-svg :src="require(`../assets/svg/${icon}.svg`)" />
        </span>
    </button>
</template>

<script>
    import * as bootstrap from 'bootstrap';
    import InlineSvg from 'vue-inline-svg';

    export default {
        name: 'Button',

        components: {
            InlineSvg
        },

        props: {
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            type: {
                type: String,
                default: '',
                required: false,
            },
            to: {
                // type: String,
                default: '',
                required: false,
            },
            icon: {
                type: String,
                default: null,
                required: false,
            },
            iconPosition: {
                type: String,
                default: '',
                required: false,
            },
            id: {
                type: String,
                required: false,
            },
            modalId: {
                type: String,
                required: false,
                default: '',
            },
            outline: {
                type: Boolean,
                required: false,
                default: false,
            },
            size: {
                type: String,
                required: false,
                default: 'md',
            },
            text: {
                type: String,
                required: false,
            },
            variant: {
                type: String,
                required: false,
                default: 'primary',
            },
        },

        methods: {
            openModal(modalId) {
                const myModalEl = document.getElementById(modalId);
                const modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
                modal.toggle();
            }
        }
    }
</script>
