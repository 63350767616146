import { firebase } from './firebase';
import { store } from './store';

// firebase.auth().onAuthStateChanged(function(user) {
//     if(user) {
//         store.commit(`user/USER_DETAILS`, user);
//     } else {
//         store.commit(`user/LOGOUT`);
//     }
// });

firebase.auth().onAuthStateChanged(function(user) {
    if(user) {
        if(user.email.endsWith('blacheyong.com')) {
            // console.log(user.email);
            store.commit(`user/USER_DETAILS`, user);
        } else {
            console.log(user.uid);
            // code below: https://firebase.google.com/docs/auth/admin/manage-users#delete_a_user 
            // Admin SDK module ref: https://stackoverflow.com/questions/43481830/error-admin-is-not-defined-using-http-request-with-cloud-functions-for-firebase/43482017
            // admin
            //     .auth()
            //     .deleteUser(user.uid)
            //     .then(() => {
            //         console.log('Successfully deleted user');
            //     })
            //     .catch((error) => {
            //         console.log('Error deleting user:', error);
            //     });
            store.commit(`user/LOGOUT`);
        } 
    } else {
        store.commit(`user/LOGOUT`);
    }
});