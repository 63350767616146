<template>
  <div>
    <Navbar
      :logout="logout"
      :userProfile="userProfile"
      :loggedIn="loggedIn"
    />
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import firebase from 'firebase';
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',

  components: {
    Navbar
  },

  computed: {
    ...mapGetters('user', {
      userProfile: 'userProfile',
      loggedIn: 'loggedIn'
    }),
  },
  methods: {
    ...mapActions('user', {
      logout: 'logout'
    }),
    ...mapActions('projects', {
      fetchProjects: 'fetchProjects',
    }),
    ...mapActions('personas', {
      fetchPersonas: 'fetchPersonas'
    }),
  },

  created() {
    this.$store.dispatch('projects/fetchProjects');
    this.$store.dispatch('personas/fetchPersonas');
  },

  // Check if user is logged in: yes -> homepage , no -> login page
  setup() {
    const router = useRouter();
    const route = useRoute();

    onBeforeMount(() => {
      firebase.auth().onAuthStateChanged((user) => {

        // If use not loggedIn and not in client view -> redirect to login page
        if( !user && !route.params.clientProjectName && !route.params.clientPersonaId ) {
          router.replace('/');
        } else if(!user.email.endsWith('blacheyong.com')) { // If user email doesn't end with blacheyong.com

          router.replace('/login-error');
          // console.log(user.uid);

          // user.delete().then(function() {
          //   // User deleted.
          //   console.log('deleted user');
          //   // router.replace('/login-error'); // user deleted, but route not working because function re-trigger onBeforeMount. Needs to refresh
          // }, function(error) {
          //   // An error happened.
          //   console.log('error when deleting user' + error);
          // });

        } else if(user.email.endsWith('blacheyong.com') && route.name == 'Login' || route.name == 'LoginError') { // If login is successful + we were on a login page
          router.replace('/home');
        }
      })
    });
  },
}
</script>