import { personasCollection } from '../../firebase';
import { projectsCollection } from '../../firebase';
import router from '../../router';

const state = {
    personas: [],
};

const getters = {
    personas: ({ personas }) => personas,

    personasTotal: state => {
        return state.personas.length - 2 // - 2 because of 2 empty "new" persona doc, used when creating a new persona
    },

    personaByProject: (state) => (parent) => {
        let personasSpecificProject = [];

        state.personas.forEach(persona => {
            if(persona.parentProject === parent) {
                personasSpecificProject.push(persona);
            }
        })

        personasSpecificProject.sort((a, b) => {
            return b.lastUpdated - a.lastUpdated
        })

        return personasSpecificProject;
    },
    personaPublishedByProject: (state) => (parent) => {
        let personasSpecificProject = [];

        state.personas.forEach(persona => {
            if(persona.parentProject === parent && persona.isPublished === true) {
                personasSpecificProject.push(persona);
            }
        })

        return personasSpecificProject;
    },
    getPersonaIndex: (state) => (parent, id) => {
        let personasSpecificProject = [];
        let personaIndex = '';

        state.personas.forEach(persona => {
            if(persona.parentProject === parent && persona.isPublished) {
                personasSpecificProject.push(persona);
            }
        })

        for(let i = 0; i < personasSpecificProject.length; i++) {
            if(personasSpecificProject[i].id == id) {
                personaIndex = i;
            }
        }

        return personaIndex;
    },

    fetchPersona: (state) => (personaId) => {
        let personaByName = [];
        state.personas.forEach(persona => {
            if(persona.id === personaId) {
                personaByName.push(persona)
            }
        })

        return personaByName;
    },

    countPersonaByProject: (state) => (parent) => {
        let personasSpecificProject = [];

        state.personas.forEach(persona => {
            if(persona.parentProject === parent) {
                personasSpecificProject.push(persona);
            }
        })

        return personasSpecificProject.length;
    },
    countPublishedPersonaByProject: (state) => (parent) => {
        let personasSpecificProject = [];

        state.personas.forEach(persona => {
            if(persona.parentProject === parent && persona.isPublished) {
                personasSpecificProject.push(persona);
            }
        })

        return personasSpecificProject.length;
    },
};

const mutations = {
    SET_PERSONAS(state, val) {
        state.personas = val;
    },
    PERSONA_DETAILS(state, persona) {
        state.personas.push({
            id: persona.id,
            parentProject: persona.parentProject,
            parentProjectId: persona.parentProjectId,
            personaType: persona.personaType,
            thumbnail: persona.thumbnail,
            name: persona.name,
            description: persona.description,
            tags: persona.tags,
            quote: persona.quote,
            keywords: persona.keywords,
            story: persona.story,
            typicalDay: persona.typicalDay,
            sliders: persona.sliders,
            textComponents: persona.textComponents,
            imageTopLeft: persona.imageTopLeft,
            imageBottomLeft: persona.imageBottomLeft,
            imageMain: persona.imageMain,
            imageBottom: persona.imageBottom,
            lastUpdated: persona.lastUpdated,
            isPublished: persona.isPublished,
        })
    },
};

const actions = {
    fetchPersonas({ commit }) {
        console.log('action: fetchPersonas');
        personasCollection.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('empty collection');
          } else {
            let personasDB = [{id: 'new'}, ];
            snapshot.forEach(doc => {
                personasDB.push({
                    id: doc.id,
                    parentProject: doc.data().parentProject,
                    parentProjectId: doc.data().parentProjectId,
                    personaType: doc.data().personaType,
                    thumbnail: doc.data().thumbnail,
                    name: doc.data().name,
                    description: doc.data().description,
                    tags: doc.data().tags,
                    quote: doc.data().quote,
                    keywords: doc.data().keywords,
                    story: doc.data().story,
                    typicalDay: doc.data().typicalDay,
                    sliders: doc.data().sliders,
                    textComponents: doc.data().textComponents,
                    imageTopLeft: doc.data().imageTopLeft,
                    imageBottomLeft: doc.data().imageBottomLeft,
                    imageMain: doc.data().imageMain,
                    imageBottom: doc.data().imageBottom,
                    lastUpdated: doc.data().lastUpdated,
                    isPublished: doc.data().isPublished,
                });
            });
  
            commit('SET_PERSONAS', personasDB);
          }
        });
    },

    savePersona({ commit }, { parentProject, parentProjectId, personaType, tags, keywords, story, typicalDay, sliders, textComponents, thumbnail, imageTopLeft, imageBottomLeft, imageMain, imageBottom, isPublished, btnId }) { // isPublished
        console.log('action: savePersona');
        // Add project to firestore DB
        const form = document.querySelector('#save-persona-form');
            
        personasCollection.add({
            parentProject,
            parentProjectId,
            personaType,
            thumbnail,
            name: form.name.value,
            description: form.description.value,
            tags,
            quote: form.quote.value,
            keywords,
            story,
            typicalDay,
            sliders,
            textComponents,
            imageTopLeft,
            imageBottomLeft,
            imageMain,
            imageBottom,
            lastUpdated: Date.now(),
            isPublished
        })
            .then((docRef) => {
                console.log("*Document written with ID: ", docRef.id);
                // -> mutation -> state
                const persona = { 
                    id: docRef.id,
                    parentProject,
                    parentProjectId,
                    personaType,
                    thumbnail,
                    name: form.name.value,
                    description: form.description.value,
                    tags,
                    quote: form.quote.value,
                    keywords,
                    story,
                    typicalDay,
                    sliders,
                    textComponents,
                    imageTopLeft,
                    imageBottomLeft,
                    imageMain,
                    imageBottom,
                    lastUpdated: Date.now(),
                    isPublished
                };
                commit('PERSONA_DETAILS', persona);

                setTimeout(function(){
                    router.push({ name: 'PersonaProfile', params: { personaId: docRef.id } })
                }, 3000)

                // Success notification
                if(btnId) {
                    const savePersonaBtn = document.getElementById(btnId);
                    savePersonaBtn.classList.add('disabled');
                    savePersonaBtn.getElementsByTagName('span')[1].innerHTML = '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0 .3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-11.00000002 11.00000002c-.36048396.3604839-.92771502.3882135-1.32000622.0831886l-.09420734-.0831886-5-5c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136.36048396-.3604839.92771502-.3882135 1.32000622-.0831886l.09420734.0831886 4.29289322 4.2921068z" fill="#242831"/></svg> Published'
                    savePersonaBtn.getElementsByTagName('span')[0].style.display = 'none';
                    setTimeout(function(){
                        savePersonaBtn.classList.remove('disabled');
                        savePersonaBtn.getElementsByTagName('span')[1].innerHTML = 'Publish persona'
                        savePersonaBtn.getElementsByTagName('span')[0].style.display = 'inline-block';
                    }, 3000)
                }
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

            // Update last_updated field of parentProject
            projectsCollection.doc(parentProjectId).update({
                last_updated: Date.now(),
            })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
    },

    updatePersona({ commit }, { id, parentProject, parentProjectId, personaType, tags, keywords, story, typicalDay, sliders, textComponents, thumbnail, imageTopLeft, imageBottomLeft, imageMain, imageBottom, isPublished, btnId }) { // isPublished
        console.log('updatePersona action');
        const form = document.querySelector('#save-persona-form');

        personasCollection.doc(id).set({
            parentProject,
            parentProjectId,
            personaType,
            thumbnail,
            name: form.name.value,
            description: form.description.value,
            tags,
            quote: form.quote.value,
            keywords,
            story,
            typicalDay,
            sliders,
            textComponents,
            imageTopLeft,
            imageBottomLeft,
            imageMain,
            imageBottom,
            lastUpdated: Date.now(),
            isPublished
        })
            .then(() => {
                const persona = {
                    parentProject,
                    parentProjectId,
                    personaType,
                    thumbnail,
                    name: form.name.value,
                    description: form.description.value,
                    tags,
                    quote: form.quote.value,
                    keywords,
                    story,
                    typicalDay,
                    sliders,
                    textComponents,
                    imageTopLeft,
                    imageBottomLeft,
                    imageMain,
                    imageBottom,
                    lastUpdated: Date.now(),
                    isPublished
                };
                commit('PERSONA_DETAILS', persona);

                // Success notification
                if(btnId) {
                    const savePersonaBtn = document.getElementById(btnId);
                    savePersonaBtn.classList.add('disabled');
                    savePersonaBtn.getElementsByTagName('span')[1].innerHTML = '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0 .3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-11.00000002 11.00000002c-.36048396.3604839-.92771502.3882135-1.32000622.0831886l-.09420734-.0831886-5-5c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136.36048396-.3604839.92771502-.3882135 1.32000622-.0831886l.09420734.0831886 4.29289322 4.2921068z" fill="#242831"/></svg> Published'
                    savePersonaBtn.getElementsByTagName('span')[0].style.display = 'none';
                    setTimeout(function(){
                        savePersonaBtn.classList.remove('disabled');
                        savePersonaBtn.getElementsByTagName('span')[1].innerHTML = 'Publish persona'
                        savePersonaBtn.getElementsByTagName('span')[0].style.display = 'inline-block';
                    }, 3000)
                }
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });

        // Update last_updated field of parentProject
        projectsCollection.doc(parentProjectId).update({
            last_updated: Date.now(),
        })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}