<template>
    <nav :class="`navbar navbar-expand-lg navbar-dark bg-dark ${[ clientViewPersonaPage ? 'navbar--client-persona' : '' ]}`">
        <div class="container-xl">
            <div class="navbar__inner d-flex align-items-center justify-content-between">
                <div v-if="!clientViewPersonaPage" class="d-flex align-items-center">
                    <router-link v-if="navbarBrandLink" class="navbar-brand" to="/home"><inline-svg :src="require('../assets/svg/logo-blacheyong-co.svg')" /></router-link>
                    <inline-svg v-else class="navbar-brand--no-link" :src="require('../assets/svg/logo-blacheyong-co.svg')" />
                    <span class="navbar__personas-title">Personas</span>
                </div>
                <div v-else>
                    <Button @click="back" text="Back to all" variant="outline-secondary-light" iconPosition="left" icon="icon-arrow-left" />
                </div>
                <ul v-if="loggedIn" class="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img class="navbar__avatar" :src="userProfile.picture" alt="Avatar" v-if="userProfile.picture">
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li class="dropdown-item">{{ userProfile.name }}</li>
                            <li class="dropdown-item"><a href="#" @click="logout"><inline-svg :src="require('../assets/svg/icon-logout.svg')" />Log out</a></li>
                        </ul>
                    </li>
                </ul>
                <div v-if="clientViewPersonaPage || clientViewProjectPage && !passwordPage" class="navbar__project-info">
                    {{ $route.params.clientProjectNamePersonaPage || $route.params.clientProjectName }}, <span v-if="clientViewPersonaPage">{{ personasCount($route.params.clientProjectNamePersonaPage) }}</span> <span v-if="clientViewProjectPage">{{ personasCount($route.params.clientProjectName) }}</span> persona(s)
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Button from '../components/Button.vue';
    import InlineSvg from 'vue-inline-svg';

    export default {
        name: 'Navbar',

        components: {
            Button,
            InlineSvg
        },

        props: {
            logout: {
                type: Function,
            },
            loggedIn: {
                type: Boolean,
            },
            userProfile: {
                type: Object,
            },
        },

        data() {
            return {
                clientViewProjectPage: false,
                clientViewPersonaPage: false,
            }
        },

        computed: {
            ...mapGetters({
                personasCount: 'personas/countPublishedPersonaByProject',
                projectsFiles: 'projects/projects',
            }),
            personas() {
                return this.personasCount
            },

            navbarBrandLink() {
                if (this.$route.name !== 'Login' && this.$route.name !== 'LoginError' && this.$route.name !== 'ClientPersonaProfile' && this.$route.name !== 'ClientProject' && this.$route.name !== 'ClientPassword') {
                    return true;
                } else {
                    return false;
                }
            },

            passwordPage() {
                if (this.$route.name == 'ClientPassword') {
                    return true;
                } else {
                    return false;
                }
            }
        },

        methods: {
            back() {
                this.$router.push({ name: 'ClientProject', params: { clientProjectName: this.$route.params.clientProjectNamePersonaPage } })
            },
        },

        watch: {
            "$route.params.clientProjectNamePersonaPage": { // Can't use clientPersonaId because of v-for used to display persona in ClientPersonaProfile
                handler: function(value) {
                    if(value !== undefined) {
                        this.clientViewPersonaPage = true;
                    } else {
                        this.clientViewPersonaPage = false;
                    }
                },
                deep: true,
                immediate: true,
            },
            "$route.params.clientProjectName": {
                handler: function(value) {
                    if(value !== undefined) {
                        this.clientViewProjectPage = true;
                    } else {
                        this.clientViewProjectPage = false;
                    }
                },
                deep: true,
                immediate: true,
            },
        },
    }
</script>