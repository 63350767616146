import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login-error',
    name: 'LoginError',
    component: () => import('../views/LoginError.vue')
  },
  {
    path: '/:name',
    name: 'Project',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/new-persona',
    name: 'NewPersona',
    component: () => import('../views/NewPersona.vue')
  },
  {
    path: '/:projectName/choose-persona-type',
    name: 'ChoosePersonaType',
    component: () => import('../views/ChoosePersonaType.vue')
  },
  {
    path: '/new-persona-role',
    name: 'NewPersonaRole',
    component: () => import('../views/NewPersonaRole.vue')
  },
  {
    path: '/:projectName/:mode/:personaType/:personaId',
    name: 'PersonaProfile',
    component: () => import('../views/PersonaProfile.vue')
  },
  {
    path: '/share/:clientProjectNamePersonaPage/:clientPersonaId',
    name: 'ClientPersonaProfile',
    component: () => import('../views/ClientPersonaProfile.vue')
  },
  {
    path: '/share/:clientProjectName',
    name: 'ClientProject',
    component: () => import('../views/ClientProject.vue')
  },
  {
    path: '/share/:clientProjectName/auth',
    name: 'ClientPassword',
    component: () => import('../views/ClientPassword.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router