// Vuex4 version
import personas from '@/store/personas'
import projects from '@/store/projects'
import user from '@/store/user'
import { createStore } from 'vuex'

export const store = createStore({
    modules: {
        personas,
        projects,
        user,
    }
})