import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyATk7gvIE_LjKwO44S5vv-m4fndNSgUYv8",
    authDomain: "persona-tool.firebaseapp.com",
    projectId: "persona-tool",
    storageBucket: "persona-tool.appspot.com",
    messagingSenderId: "537017638449",
    appId: "1:537017638449:web:32c088eedf523913d2bf10",
    measurementId: "G-JE7SMDL0Q4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Utils
const db = firebase.firestore();
const auth = firebase.auth();

// Collection references
const projectsCollection = db.collection('projects');
const personasCollection = db.collection('personas');

// Export utils/refs
export {
  db,
  auth,
  personasCollection,
  projectsCollection,
  firebase,
}