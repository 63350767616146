import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import './auth';
import './favicon'
import 'bootstrap';
// import VueI18n from 'vue-i18n'

import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;

// Import Main Styles
import './assets/styles/main.scss';

createApp(App).use(router).use(store).use(VueClipboard).mount('#app') // .use(VueI18n)
